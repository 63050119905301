import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { ProductAPI, ProductsService } from "../generated"
import { ODataOptions } from "../handmade"

export const useProducts = (tenancyId: UUID, options?: ODataOptions) =>
  useQuery(["products", options], () => ProductsService.productsGetAllAsync({ tenancyId, ...options }), {
    enabled: !!tenancyId,
    cacheTime: 0,
  })

export const useProductStoreFronts = (tenancyId: UUID, productId: UUID, options?: ODataOptions) =>
  useQuery(
    ["productsStoreFronts"],
    () => ProductsService.productsGetStoreFrontsAllAsync({ tenancyId, productId, ...options }),
    { enabled: !!tenancyId && !!productId }
  )

export const useProductVariantOptions = (tenancyId: UUID, productId: UUID) =>
  useQuery(
    ["productsVariantOptions"],
    () => ProductsService.productsGetOptionValuesAllAsync({ tenancyId, productId }),
    {
      enabled: !!tenancyId && !!productId,
    }
  )

export const useProduct = (tenancyId: UUID, id: UUID) =>
  useQuery([`products`, tenancyId, id], () => ProductsService.productsGetAsync({ tenancyId, id }), { cacheTime: 0 })

export const useCreateProductMutation = ({ onSuccess }: { onSuccess: () => void }, tenancyId: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(
    (product: Partial<ProductAPI>) =>
      ProductsService.productsPostAsync({
        tenancyId,
        requestBody: product as any,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["products"])
        onSuccess()
      },
      onError: () => {},
    }
  )
}

export const useUpdateProductMutation = ({ onSuccess }: { onSuccess: () => void }, tenancyId: UUID, id: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(
    (product: Partial<ProductAPI>) =>
      ProductsService.productsPatchAsync({
        tenancyId,
        id,
        requestBody: product as any,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["products"])
        onSuccess()
      },
      onError: () => {},
    }
  )
}

export const useDeleteProductMutation = ({ onSuccess }: { onSuccess: () => void }, tenancyId: UUID, id: UUID) => {
  const queryClient = useQueryClient()

  return useMutation(() => ProductsService.productsDeleteAsync({ tenancyId, id }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["products"])
      onSuccess()
    },
    onError: () => {},
  })
}

export const useProductAssociatedProducts = (tenancyId: UUID, productId: string) =>
  useQuery(
    ["associatedProducts", productId],
    () => ProductsService.productGetAssociatedProductsAsync({ tenancyId, productId }),
    { enabled: !!productId }
  )

export const useProductCrossLinkedProducts = (tenancyId: UUID, productId: string) =>
  useQuery(
    ["crossLinkedProducts", productId],
    () => ProductsService.productGetCrossLinkedProductsAsync({ tenancyId, productId }),
    { enabled: !!productId }
  )
