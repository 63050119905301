import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { ProductVariant, ProductVariantsService } from "../generated"

export const useProductVariants = (tenancyId: UUID, parentId: UUID) =>
  useQuery(["productVariants", parentId], () =>
    ProductVariantsService.productVariantsGetAllAsync({ parentId, tenancyId })
  )

export const useProductVariant = (tenancyId: UUID, parentId: UUID, id: UUID) =>
  useQuery(["productVariant"], () => ProductVariantsService.productVariantsGetAsync({ id, parentId, tenancyId }), {
    enabled: !!id,
  })

export const useProductVariantStoreFronts = (tenancyId: UUID, parentId: UUID, variantId: UUID) =>
  useQuery(["productVariantStoreFronts"], () =>
    ProductVariantsService.productVariantsStoreFrontsGetAllAsync({ parentId, tenancyId, variantId })
  )

export const useCreateProductVariantMutation = (
  { onSuccess }: { onSuccess: () => void },
  parentId: UUID,
  tenancyId: UUID
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (productVariant: Partial<ProductVariant>) =>
      ProductVariantsService.productVariantsPostAsync({ parentId, tenancyId, requestBody: productVariant as any }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["productVariants"])
        onSuccess()
      },
      onError: () => {},
    }
  )
}

export const useUpdateProductVariantMutation = (
  { onSuccess }: { onSuccess: () => void },
  parentId: UUID,
  tenancyId: UUID,
  id: UUID
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (productVariant: Partial<ProductVariant>) =>
      ProductVariantsService.productVariantsPatchAsync({ parentId, tenancyId, id, requestBody: productVariant as any }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["productVariants"])
        onSuccess()
      },
      onError: () => {},
    }
  )
}

export const useDeleteProductVariantMutation = (
  { onSuccess }: { onSuccess: () => void },
  parentId: UUID,
  tenancyId: UUID,
  id: UUID
) => {
  const queryClient = useQueryClient()

  return useMutation(() => ProductVariantsService.productVariantsDeleteAsync({ id, parentId, tenancyId }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["productVariants"])
      onSuccess()
    },
    onError: () => {},
  })
}

export const useProductVariantAssociatedProducts = (tenancyId: UUID, productId: string, variantId: string) =>
  useQuery(
    ["variantAssociatedProducts", productId, variantId],
    () => ProductVariantsService.productVariantGetAssociatedProductsAsync({ tenancyId, productId, variantId }),
    { enabled: !!variantId }
  )

export const useProductVariantCrossLinkedProducts = (tenancyId: UUID, productId: string, variantId: string) =>
  useQuery(
    ["variantCrossLinkedProducts", productId, variantId],
    () => ProductVariantsService.productVariantGetCrossLinkedProductsAsync({ tenancyId, productId, variantId }),
    { enabled: !!variantId }
  )
