import { Button, Flex, HStack, Stack, Text } from "@chakra-ui/react"
import { ChevronLeftIcon } from "@iac/components.icons.react"
import { useTranslation } from "@iac/translations.i18n-instance"
import { FC, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

import { EditVariantForm } from "./components/EditVariantForm"
import { setDefaultAddProductValues } from "./utils/defaultAddProductValuesUtils"
import { ProductAPI, ProductVariant, api, mockData } from "api"
import { TENANCY_ID } from "common/constants"
import { Statuses } from "common/statuses"
import { route } from "features/app/routes"

type ConfirmVariantsProps = {
  product: ProductAPI
  goBack: () => void
}

export const ConfirmVariants: FC<ConfirmVariantsProps> = ({ product, goBack }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const pageRef = useRef<HTMLDivElement>(null)
  const [variant, setVariant] = useState<ProductVariant>(mockData.initProductVariant)
  const [productVariants, setProductVariants] = useState<ProductVariant[]>([])
  const variantIndex = productVariants.findIndex((item) => item.id === variant.id)

  useEffect(() => {
    pageRef.current?.scrollIntoView()
  }, [variant])

  const createProductMutation = api.useCreateProductMutation(
    {
      onSuccess: () => {
        navigate(route.products.path)
      },
    },
    TENANCY_ID
  )

  useEffect(() => {
    const productVariants: ProductVariant[] = product.variants as ProductVariant[]
    setProductVariants(productVariants)
    const findVariant = productVariants[0] as ProductVariant
    setVariant(findVariant)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goToPrevVariant = (variant: ProductVariant) => {
    const updatedVariants = productVariants.map((item) => (item.id === variant.id ? variant : item))
    setProductVariants(updatedVariants)
    if (productVariants[variantIndex - 1]?.id) {
      setVariant(productVariants[variantIndex - 1])
    }
  }

  const saveNext = (variant: ProductVariant): void => {
    const updatedVariants = productVariants.map((item) => (item.id === variant.id ? variant : item))
    setProductVariants(updatedVariants)
    if (productVariants[variantIndex + 1]?.id) {
      setVariant(productVariants[variantIndex + 1])
    } else {
      setDefaultAddProductValues(product)
      const productItem = {
        supplierId: product.supplierId,
        supplierSku: product.supplierSku,
        countryOfOriginId: product.countryOfOriginId,
        productCategoryId: product.productCategoryId,
        unitOfMeasureId: product.unitOfMeasureId,
        inventoryType: product.inventoryType,
        hsCode: product.hsCode,
        sellingTaxCategoryId: product.sellingTaxCategoryId,
        initialStock: product.initialStock,
        initialCost: product.initialCost,
        carryingCost: product.carryingCost,
        retailPrice: product.retailPrice,
        supplierBarcode: product.supplierBarcode,
        variants: updatedVariants,
        storeFronts: product.storeFronts,
        buyingTaxCategoryId: product.buyingTaxCategoryId || null,
        officialLicence: product.officialLicence,
        status: Statuses.Active,
        images: product.images,
        associatedToProducts: product.associatedToProducts,
        crossLinkedToProducts: product.crossLinkedToProducts,
        associatedVariants: product.associatedVariants,
        crossLinkedVariants: product.crossLinkedVariants,
        translations: [
          {
            languageId: "57b0e5c7-a567-499c-a07b-cbf712b707a2",
            name: product.name,
            description: product.description,
            shortName: product.shortName,
          },
        ],
      }
      createProductMutation.mutate(productItem)
    }
  }

  const variantNumber = variantIndex + 1

  return (
    <Stack ref={pageRef} w="100%">
      <Flex align="center" justify="space-between" mb={0} py={8}>
        <HStack gap={4}>
          <Button
            borderColor="info.01"
            borderWidth={1}
            fontSize="unset"
            padding={3}
            variant="ghost"
            width="40px"
            onClick={() => goBack()}
          >
            <ChevronLeftIcon color="info.01" />
          </Button>
          <Text color="text.01" fontSize="2xl" fontWeight="bold">
            {t("product.reviewVariant")}
          </Text>
          <Text color="text.02" fontSize="xl" fontWeight="bold">
            {product.name}
          </Text>
        </HStack>
        <Text color="text.02" fontSize="xl" fontWeight="bold">
          {t("product.variantNumberOfAllVariants", {
            variantNumber,
            allVariants: productVariants.length,
          })}
        </Text>
      </Flex>

      {variant.id && (
        <EditVariantForm
          key={variant.id}
          allVariantsNumber={productVariants.length}
          editedVariant={variant}
          goToPrevVariant={goToPrevVariant}
          product={product}
          saveNext={saveNext}
          variantNumber={variantNumber}
        />
      )}
    </Stack>
  )
}
