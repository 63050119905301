import { FC } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { ProductsChanges } from "./ProductsChanges"
import { getDefaultAddProductValues, setDefaultAddProductValues } from "./utils/defaultAddProductValuesUtils"
import { ProductAPI, api, mockData } from "api"
import { TENANCY_ID } from "common/constants"
import { Statuses } from "common/statuses"
import { route } from "features/app/routes"

export const AddProduct: FC = () => {
  const params = useParams()
  const navigate = useNavigate()

  const createProductMutation = api.useCreateProductMutation(
    {
      onSuccess: () => {
        localStorage.removeItem("productVariants")
        navigate(route.products.path)
      },
    },
    TENANCY_ID
  )

  const addProduct = (productAPI: Partial<ProductAPI>): void => {
    setDefaultAddProductValues(productAPI)
    const productItem = {
      supplierId: productAPI.supplierId,
      supplierSku: productAPI.supplierSku,
      countryOfOriginId: productAPI.countryOfOriginId,
      productCategoryId: productAPI.productCategoryId,
      unitOfMeasureId: productAPI.unitOfMeasureId,
      inventoryType: productAPI.inventoryType,
      hsCode: productAPI.hsCode,
      sellingTaxCategoryId: productAPI.sellingTaxCategoryId,
      initialStock: productAPI.initialStock,
      initialCost: productAPI.initialCost,
      carryingCost: productAPI.carryingCost,
      retailPrice: productAPI.retailPrice,
      variants: productAPI.variants,
      storeFronts: productAPI.storeFronts,
      buyingTaxCategoryId: productAPI.buyingTaxCategoryId || null,
      officialLicence: productAPI.officialLicence,
      status: Statuses.Active,
      images: productAPI.images,
      associatedToProducts: productAPI.associatedToProducts,
      crossLinkedToProducts: productAPI.crossLinkedToProducts,
      associatedVariants: productAPI.associatedVariants,
      crossLinkedVariants: productAPI.crossLinkedVariants,
      translations: [
        {
          languageId: "57b0e5c7-a567-499c-a07b-cbf712b707a2",
          name: productAPI.name,
          description: productAPI.description,
          shortName: productAPI.shortName,
        },
      ],
    }
    createProductMutation.mutate(productItem)
  }

  const initialProductAPI = params.sCode
    ? { ...mockData.initProductAPI, supplierBarcode: params.sCode }
    : mockData.initProductAPI

  return (
    <ProductsChanges
      initialProductAPI={{ ...initialProductAPI, ...getDefaultAddProductValues() }}
      onSubmit={addProduct}
    />
  )
}
