import { ProductAPI } from "../generated"

export const initProductAPI = {
  id: "",

  name: "",
  description: "",
  shortName: "",
  images: [],
  supplierId: "",
  supplierSku: "",
  countryOfOriginId: 0,
  productCategoryId: "",
  unitOfMeasureId: "",
  inventoryType: "",
  tags: "",
  makeItFavorite: false,

  hsCode: "",
  sellingTaxCategoryId: "",

  initialStock: 0,
  totalStock: 0,
  initialCost: 0,
  carryingCost: 0,
  retailPrice: 0,

  supplierBarcode: "",

  buyingTaxCategoryId: "",
  variants: [
    {
      receiptDisplayName: "",
      sku: "",
      supplierSku: "",
      supplierBarcode: "",
      cost: 0,
      status: "",
      notes: "",
      variantOptionValues: [],
      storeFronts: [],
    },
  ],
  storeFronts: [],

  deletedTS: "",
  officialLicence: false,
  status: "",
} as ProductAPI
