import { Flex, Input, InputGroup, InputLeftElement, Tag, TagCloseButton, TagLabel, Text } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "@iac/translations.i18n-instance"
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { useForm } from "react-hook-form"

import { AssociatedProductsControl } from "./components/associatedProducts/AssociatedProductsControl"
import { SelectedVariants } from "./ProductsChanges"
import { useValidationSchemes } from "./utils/validationSchemеs"
import { AllVariants, createVariants, generateMixedNames, getGrouping, restType } from "./utils/variantUtiles"
import { ProductAPI, ProductVariant, StoreFront, TenancyCurrency, api } from "api"
import { CommonFormControl, FormButtons, FormField } from "common/components"
import { TENANCY_ID } from "common/constants"
import { LONG_FORM_ELEMENT_WIDTH, SHORT_FORM_ELEMENT_WIDTH, formStyles } from "common/theme"

type DefaultVariantsProps = {
  productAPIState: [ProductAPI, Dispatch<SetStateAction<ProductAPI>>]
  onConfirm: (productAPI: Partial<ProductAPI>) => void
  variants: SelectedVariants
  onReviewVariants: () => void
}

type DefaultProductVariant = Omit<ProductVariant, "productId" | "stock" | "images">

type ProductFormValues = {
  attributes: { name: string; value: string }[]
  supplierSku: string
  supplierBarcode: string | null
  variantStock: string
  variantCost: string
  variantRetailPrice: string
}

export const DefaultVariants: FC<DefaultVariantsProps> = ({
  productAPIState,
  variants,
  onConfirm,
  onReviewVariants,
}) => {
  const { t } = useTranslation()
  const { defaultVariantsSchema } = useValidationSchemes()

  const [productAPI, setProductAPI] = productAPIState
  const [count, setCount] = useState(0)
  const [mixed, setMixed] = useState<{ name: string; productId: string }[]>([])
  const [symbol, setSymbol] = useState("")
  const [allVariants, setAllVariants] = useState<AllVariants[]>([])

  const [productVariants, setProductVariants] = useState<DefaultProductVariant[]>([])

  const { data: variantOptionsData } = api.useVariantOptions(TENANCY_ID, { expand: "variantoptionvalues" })
  const { data: tenancyCurrencies } = api.useTenancyCurrencies(TENANCY_ID, { expand: "currency" })
  const { data: storeFrontsData } = api.useStoreFronts(TENANCY_ID)

  const {
    formState: { errors, isValid },
    getValues,
    register,
    setValue,
  } = useForm<ProductFormValues>({
    resolver: zodResolver(defaultVariantsSchema),
    mode: "all",
    defaultValues: {
      supplierSku: "",
      supplierBarcode: productAPI.supplierBarcode,
      variantStock: undefined,
      variantCost: undefined,
      variantRetailPrice: undefined,
    },
  })
  const formFieldProps = { errors, register }

  useEffect(() => {
    if (variantOptionsData?.value) {
      const variants = variantOptionsData?.value.map((item) => item.variantOptionValues && item)
      setAllVariants(variants)
    }
  }, [variantOptionsData])

  useEffect(() => {
    if (tenancyCurrencies) {
      const global = tenancyCurrencies?.value?.find((item) => item.isDefault) as TenancyCurrency
      setSymbol(global?.currency?.symbol || "")
    }
  }, [tenancyCurrencies])

  useEffect(() => {
    if (mixed.length) {
      setCount(mixed.length)
      setValue("variantStock", Math.round(productAPI.initialStock / mixed.length).toString(), { shouldValidate: true })
      setValue(
        "variantCost",
        (
          Math.round(((productAPI.initialCost + (productAPI.carryingCost ?? 0)) / productAPI.initialStock) * 100) / 100
        ).toString(),
        { shouldValidate: true }
      )
      setValue("variantRetailPrice", productAPI.retailPrice.toString(), { shouldValidate: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixed.length])

  const grouping = variants.flatMap((item) =>
    item.optionValues.map((option) => ({
      id: option.id,
      group: item.name,
      text: option.value,
    }))
  )

  const makeVariants = (items: restType[]): void => {
    setMixed(generateMixedNames(items))
    setProductVariants(createVariants(items, allVariants, productAPI))
  }

  useEffect(() => {
    setCount(mixed.length)
    if (allVariants.length > 0 && allVariants.every((item) => !!item)) {
      makeVariants(getGrouping(grouping, allVariants))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allVariants])

  useEffect(() => {
    setValue("supplierSku", productAPI.supplierSku)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const removeVariant = (variant: { name: string; productId: string }): void => {
    const filteredOptions = mixed.filter((item) => item.productId !== variant.productId)
    setMixed(filteredOptions)
    setProductVariants((variants) => variants.filter((item) => item.id !== variant.productId))
  }

  const reviewVariants = () => {
    setProductAPI({ ...productAPI, ...getValues(), variants: getFinalProductVariants() })
    onReviewVariants()
  }

  const confirm = () => {
    onConfirm({
      ...productAPI,
      ...getValues(),
      variants: getFinalProductVariants(),
    })
  }

  const getFinalProductVariants = () =>
    productVariants.map((variant) => ({
      ...variant,
      cost: +getValues().variantCost,
      supplierBarcode: getValues().supplierBarcode,
      storeFronts: variant.storeFronts.map((store) => ({
        ...store,
        retailPrice: +getValues().variantRetailPrice,
        stock: +store.stock ? +store.stock : +getValues().variantStock,
      })),
    }))

  return (
    <>
      <Flex direction="column" pl="92px" width="100%">
        <CommonFormControl>
          <Text color="text.01" fontSize={14} fontWeight="bold">
            {t("product.variantsNumber", { count })}
          </Text>
          <Flex
            borderColor="#e3dcde"
            borderRadius={5}
            borderWidth={1}
            flexWrap="wrap"
            minHeight="2rem"
            mt={4}
            padding="0.25rem 0.25rem 0"
            width={LONG_FORM_ELEMENT_WIDTH}
          >
            {mixed.map((variant) => (
              <Flex key={variant.name} margin="0 .25rem 0.25rem 0">
                <Tag>
                  <TagLabel>{variant.name}</TagLabel>
                  <TagCloseButton data-testid="delete-variant-button" onClick={() => removeVariant(variant)} />
                </Tag>
              </Flex>
            ))}
          </Flex>
        </CommonFormControl>
        <FormField<ProductFormValues>
          label={t("product.supplierSKU")}
          name="supplierSku"
          isRequired
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Input data-testid="product-supplier-sku-field" />
        </FormField>
        <FormField<ProductFormValues>
          label={t("product.supplierBarcode")}
          name="supplierBarcode"
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Input data-testid="product-supplier-barcode-field" type="number" />
        </FormField>
        <FormField<ProductFormValues>
          label={t("product.initialStockPerVariant")}
          name="variantStock"
          isRequired
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <Input data-testid="product-initial-stock-variant-field" type="number" />
        </FormField>
        <FormField<ProductFormValues>
          label={t("product.unitCostPerVariant")}
          name="variantCost"
          isRequired
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <InputGroup>
            <InputLeftElement children={symbol} pointerEvents="none" />
            <Input data-testid="product-unit-cost-variant-field" {...register("variantCost")} type="number" />
          </InputGroup>
        </FormField>
        <FormField<ProductFormValues>
          label={t("product.unitRRPPerVariant")}
          name="variantRetailPrice"
          isRequired
          {...formFieldProps}
          stackProps={formStyles.control}
        >
          <InputGroup>
            <InputLeftElement children={symbol} pointerEvents="none" />
            <Input data-testid="product-uint-rrp-variant-field" {...register("variantRetailPrice")} type="number" />
          </InputGroup>
        </FormField>
        <CommonFormControl {...formStyles.control} controlWidth="100%" label={t("product.stockPerStoreFront")}>
          {productAPI.storeFronts.map((item) => {
            const store = storeFrontsData?.value?.find((store) => store.id === item.storeFrontId) as StoreFront

            return (
              <Flex key={item.storeFrontId} mb={4} width="100%">
                <Input mr={4} value={store?.name} width={SHORT_FORM_ELEMENT_WIDTH} disabled />
                <Input
                  data-testid="product-stock-per-store-front-field"
                  type="number"
                  width={SHORT_FORM_ELEMENT_WIDTH}
                  onChange={(event) => {
                    const changes = productAPI.storeFronts.map((storeElement) => {
                      if (storeElement.storeFrontId === item.storeFrontId) {
                        return {
                          ...storeElement,
                          stock: +event.currentTarget.value,
                        }
                      }

                      return storeElement
                    })
                    setProductAPI({ ...productAPI, storeFronts: changes })
                  }}
                />
              </Flex>
            )
          })}
        </CommonFormControl>
        <AssociatedProductsControl
          onChange={(products) => {
            setProductAPI({
              ...productAPI,
              associatedToProducts: products.associatedToProducts.map((product) => ({
                associatedProductId: product.id,
              })),
              crossLinkedToProducts: products.crossLinkedToProducts.map((product) => ({
                crossLinkedProductId: product.id,
              })),
              associatedVariants: products.associatedVariants.map((product) => ({ associatedVariantId: product.id })),
              crossLinkedVariants: products.crossLinkedVariants.map((product) => ({
                crossLinkedVariantId: product.id,
              })),
            })
          }}
        />
      </Flex>
      <FormButtons
        buttonTestIdSuffix="-confirm"
        cancel={reviewVariants}
        cancelWidth="auto"
        disabled={!isValid}
        done={confirm}
        titleCancel={t("product.reviewVariant_other")}
        titleDone={t("product.confirm")}
        disableCancelButton
      />
    </>
  )
}
