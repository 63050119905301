import { SalesOrderStatus } from "api"
import { AdminRoles } from "api/generated/models/AdminRoles"
import { Status } from "common/components"
import { Statuses } from "common/statuses"
import { ValidationErrors } from "common/utils/validation"
import { CommandId } from "features/commands/useCommand/commands"

const common = {
  actions: "{{item}} actions",
  addItem: "Agregar {{name, lowercase }} ",
  addItemSuccess: "{{name}} añadido",
  addNew: "Agregar nuevo {{ item, lowercase }}",
  address: "Dirección",
  addressLine: "Línea de dirección {{ number }}",
  admin: "Administrador",
  admin_other: "Administradores",
  adminName: "$t(admin) $t(name)",
  adminRole: "$t(admin) $t(role)",
  appName: "Happy Place - BCN Shop",
  back: "Volver",
  backTo: "Volver a {{ location }}",
  cancel: "Cancelar",
  catalogue: "Catálogo",
  category: "Categoría",
  category_other: "Categorías",
  cdm: "Cloud Drive Mapper",
  city: "Ciudad",
  clear: "Claro",
  close: "Close",
  command: "Comando",
  companyName: "Nombre de la compañia",
  conditions: "Conditions",
  conductor: "Conductor",
  confirm: "Confirmar",
  confirm_header: "Confirmar eliminación",
  consumer: "Consumer",
  consumer_other: "Consumers",
  contactSupport: "contact support",
  country: "País",
  create: "Crear",
  currency: "Moneda",
  customer: "Cliente",
  customer_other: "Clientes",
  dashboard: "Tablero",
  datacentre: "Centro de datos",
  delete: "Delete",
  deliveryDate: "Fecha de entrega",
  description: "Descripción",
  details: "Detalles",
  discard: "Descartar",
  discardChanges: "Descartar cambios",
  discount: "Descuento",
  distributor: "Distributor",
  distributor_other: "Distributor",
  domain: "Dominio",
  domain_other: "Dominios",
  done: "Hecho",
  edit: "Editar",
  editItem: "Editar {{name}} ",
  email: "Correo",
  emailAddress: "$t(email) $t(address)",
  entity: "Entidad",
  entity_other: "Entidades",
  favourites: "Favoritos",
  findOutMore: "Más información",
  go: "Ir",
  goTo: "Ir a {{location}}",
  identifier: "Identificador",
  identifier_other: "Identificadores",
  idx: "Intercambio de identidades",
  ifProblemPersistsContact: "Si el problema persiste, póngase en contacto con",
  image: "Image",
  image_other: "Imágenes",
  inShop: "Tienda",
  integration: "Integración",
  inventory_title: "Inventario",
  invitation: "Estado de la invitación: {{status, lower}}",
  irisMenu: "Menú Iris",
  liftnshift: "Lift&Shift",
  lobby: "Lobby",
  makeDefault: "Hacer predeterminado",
  managing: "Gestión",
  migration: "Migración",
  migration_other: "Migraciones",
  mobile: "Móvil",
  name: "Nombre",
  no: "No",
  noResults: "No se han encontrado resultados",
  note: "Nota",
  notifications: "Notificaciones",
  or: "o",
  orders: "Pedidos",
  parent: "Padre",
  partner: "Partner",
  partner_other: "Partners",
  phone: "Teléfono",
  placeholderNetflix: "por ejemplo. Netflix",
  post_code: "Código postal",
  price: "Precio",
  product_categories: "Categorías de productos",
  productTitle: "Producto",
  profile: "Perfil",
  purchase: "Purchase",
  region_label: "Región",
  removeItem: "Eliminar {{name}} ",
  role: "Rol",
  root: "Root",
  root_other: "Roots",
  sale_other: "Ventas",
  save: "Guardar",
  saveChanges: "Guardar cambios",
  search: "Buscar",
  searchPlaceholder: "Buscar ...",
  searchProduct: "Buscar Producto",
  select: "Seleccionar",
  settings_string: "Ajustes",
  signin: "Log In",
  signout: "Cerrar sesión",
  somethingWentWrong: "Algo salió mal",
  sso: "Simple Sign-On",
  status_label: "Estado",
  storeFront: "Tienda",
  storeFront_other: "Tiendas",
  surpass: "¡Surp4ss!",
  synchronize: "Sincronizar",
  tenancy: "Tenancia",
  tenancy_other: "Tenancias",
  tenancyManagement: "Gestión de Tenancias",
  timeZone: "Zona horaria",
  tryAgain: "Inténtalo de nuevo",
  type: "Type",
  unableTo: "No se puede {{action, lowercase}}",
  usersnroles: "Usuarios&Roles",
  // eslint-disable-next-line sonarjs/no-duplicate-string
  variant_option: "Opción variante",
  variant_option_other: "Opciones de variantes",
  website: " Sitio Web",
  yes: "Yes",
}

const inShop = {
  addCustomer: "Añadir cliente",
  amount: "Monto",
  amountShouldBeGreatThanTotal: "La cantidad debe ser igual o mayor que el total",
  cancelOrder: "Cancelar pedido",
  card: "Tarjeta",
  cash: "Efectivo",
  changeCustomer: "Cambiar cliente",
  changePaymentMethod: "Cambiar \n método de pago \n",
  changeStorefront: "Cambiar tienda",
  deposit: "Depositar",
  due: "Debe",
  exact: "Exacto",
  iva: "IVA",
  newCustomer: "Nuevo cliente",
  newOrder: "Nueva orden",
  noReceipt: "Sin \n recibo",
  or: "O",
  orderSummary: "Resumen del pedido",
  payment: "Pago",
  paymentAccepted: "Pago \n aceptado",
  paymentDeclined: "Pago \n rechazado",
  pressToStart: "Presiona para iniciar un pedido",
  price: "Precio",
  printReceipt: "Imprimir \n recibo",
  processPayment: "Procesar pago",
  quantity: "Cantidad",
  remaining: "Restante",
  scanToStart: "Escanear un artículo para iniciar un pedido",
  selectStorefront: "Por favor seleccione una tienda",
  subtotal: "Subtotal",
  total: "Total",
}

const commands: { [key in CommandId]: string } = {
  "auth > signin": common.signin,
  "auth > signout": common.signout,
  "clipboard > copyToken": "Auth: Copiar token de autenticación al portapapeles",
  "clipboard > copyUser": "Auth: Copiar usuario como JSON al Portapapeles",
  "customer > manage > disable": "Clientes: Deshabilitar el modo Administrar cliente",
  "customer > manage > enable": "Clientes: Administrar cliente...",
  "customer > manage > selectCustomer": "{{label}}",
  "route > goTo": "Ir a Ruta: {{label}}",
}

const components = {
  deleting_modal: "¿Desea eliminar {{name}} {{type}}?",
  downloadAppPage: {
    haveYouDownloadedApp: "¿Has descargado la aplicación?",
    message: "¡Hola! Parece que estás intentando acceder a inShop a través de un navegador web.",
  },
  footer: {
    copyright: `© IAM Technology Group Ltd {{year}}. Todos los derechos reservados.`,
  },
  imagesControl: {
    chooseFiles: "Elegir archivos",
    choosePhoto: "Elige una foto",
    dragAndDropAnImageToUpload: "Arrastrar y soltar una imagen para cargar",
    images: "Imágenes",
    makeTheMainImage: "Make the main image",
    or: "O",
    photoLibrary: "Fototeca",
    uploadPhotos: "Subir fotos",
  },
  input: {
    placeholder: "Please enter a search term",
  },
  notifications: {
    clearAll: "limpiar todo",
    notifications: "Notificaciones",
    weLetYouKnowWhenYouDo: "y te diremos cuando tengas notificaciones",
    youDontHaveNotifications: "No tienes notificaciones",
  },
  outOfStockButtonNo: "No, gracias",
  outOfStockButtonYes: "Sí, por favor",
  outOfStockModalContent: "¿Te gustaría ir a la página de variantes del producto para reabastecerte?",
  outOfStockModalTitle: "El artículo está agotado",
  pagination: {
    itemsPerPage: "Artículos por página",
    next: "Next page",
    ofTotal: "de la página {{count}}",
    ofTotal_other: "de {{count}} páginas",
    page: "Página",
    previous: "Previous page",
  },
  profileMenu: {
    legalSettings: "Configuración legal",
    myProfile: "Mi Perfil",
    organisationSettings: "Configuración de Organización",
    signOut: "Salir",
  },
  search: {
    placeholder: "Buscar {{ item }}",
  },
  textarea: {
    placeholder: "Escribe un {{item, lowercase}}",
    placeholderFormNote: "Leav una nota ...",
  },
  topBar: {
    commandInput: {
      placeholder: `Search for a ${common.command.toLocaleLowerCase()}`,
    },
    tenancyInput: {
      placeholder: `Search for a ${common.tenancy.toLocaleLowerCase()}`,
    },
  },
}

const scannedBarcode = {
  addAsProduct: "Añádelo como un nuevo producto",
  addAsVariant: "Añádelo como variante",
  message: "Scanned barcode cannot be found in the system.",
}

const settings = {
  companyInfo: {
    companyInformation: "Información de la empresa",
    companyName: common.companyName,
    industry: "Industria",
    taxLabel: "Etiqueta fiscal",
    taxNumber: "Impuestos",
  },
  configSearch: {
    checkbox: "Casilla de verificación",
    go: common.go,
    placeholder: "Introduzca un término de búsqueda",
    search: common.search,
  },
  configurations: "Configuraciones",
  currencies: {
    addNewCurrency: "Añadir nueva moneda",
    currency: common.currency,
    currency_other: "Monedas",
    currencyChanges: {
      addANewCurrency: "Añadir una nueva moneda",
      currency: common.currency,
      editCurrency: common.editItem,
      exchangeRate: "Tipo de cambio",
      makeDefaultCurrency: "Hacer la moneda predeterminada del sistema",
      modal: {
        no: "No, Keep the current currency",
        question: "You made {{name}} your default Currency. Are you sure?",
        yes: "Yes, change the default currency",
      },
      select: common.select,
      tooltipLabel:
        "ISA uses live Market exchange that updates every 1 hour. You can override the exchange rate manually.",
    },
    default: "Predeterminado",
    defaultCurrency: "Moneda predeterminada",
    delete: common.delete,
    edit: common.edit,
    makeDefault: common.makeDefault,
    rate: "Tarifa",
    symbol: "Símbolo",
  },
  languages: {
    default: "Predeterminado",
    defaultLanguage: "Idioma predeterminado",
    edit: common.edit,
    enabled: "Habilitado",
    language: "Idioma",
    languageChanges: {
      addNewLanguage: "Añadir un nuevo idioma",
      editItem: common.editItem,
      enableLanguage: "Habilitar idioma",
      makeDefaultLanguage: "Hacer el idioma predeterminado del sistema",
      makeSystemLanguage: "Hacer el lenguaje del sistema",
      modal: {
        default: {
          no: "No, mantener el idioma predeterminado actual",
          question: "Has hecho de {{name}}  tu idioma predeterminado. Esto cambiará InShop a {{name}} ",
          yes: "Sí, cambiar el idioma predeterminado",
        },
        system: {
          no: "No, mantener el idioma actual del sistema",
          question: "Hiciste de {{name}}  tu moneda predeterminada. ¿Estás seguro?",
          yes: "Sí, cambiar el idioma del sistema",
        },
      },
    },
    makeDefault: common.makeDefault,
    systemLanguage: "Lenguaje del sistema",
  },
  storeFronts: {
    addNewStoreFront: "Agregar nueva tienda",
    currency: "Moneda",
    defaultProducts: "Añadir productos a la tienda de forma predeterminada",
    description: "Descripción de la tienda",
    fullSync: "Full sync",
    generateKey: "Generate key",
    hydrateStoreFront: "Hydrate store front",
    language: "Idioma",
    maintainStockLevels: "Maintain stock levels",
    physical: "Física",
    placeholder: {
      description: "Introduzca la descripción",
      name: common.placeholderNetflix,
      webAddress: "http://www.company.com",
    },
    preClearDown: "PreClearDown",
    preClearDownConfirm: "This action will remove all variants from WooCommerce. Are you sure?",
    storeFront: common.storeFront,
    storeFront_other: "Tiendas",
    type: "Tipo de tienda",
    virtual: "Virtual",
    webAddress: "Dirección Web",
    websiteType: "Tipo de sitio web",
    websiteValidation: "Debería ser subdominio + nombre + extensión",
  },
  troubleshooting: {
    clear: common.clear,
    enableRealtimeLogging: "Habilitar el registro en tiempo real",
    send: "Enviar",
    troubleshooting_title: "Solución de problemas",
  },
}

const features = {
  admin: {
    configuringTenancies: "Configuración de tenencias",
    noLinkedTenancies: "Parece que no tiene ninguna tenancia vinculada",
    problemGettingDetails: "Hubo un problema al obtener sus datos",
    problemLoggingIn: "Hubo un problema al iniciar sesión",
    settingUpYourProfile: "Configurando tu perfil",
  },
  commands,
  customers: {
    addCustomer: "Agregar cliente",
    customerType: {
      all: "Todos los clientes",
      consumer: common.consumer,
      consumer_lower: common.consumer.toLowerCase(),
      consumer_other: common.consumer_other,
      consumer_other_lower: common.consumer_other.toLowerCase(),
      customer: common.customer,
      customer_lower: common.customer.toLowerCase(),
      customer_other: common.customer_other,
      customer_other_lower: common.customer_other.toLowerCase(),
      distributor: common.distributor,
      distributor_lower: common.distributor.toLowerCase(),
      distributor_other: common.distributor_other,
      distributor_other_lower: common.distributor_other.toLowerCase(),
      partner: common.partner,
      partner_lower: common.partner.toLowerCase(),
      partner_other: common.partner_other,
      partner_other_lower: common.partner_other.toLowerCase(),
      root: common.root,
      root_lower: common.root.toLowerCase(),
      root_other: common.root_other,
      root_other_lower: common.root_other.toLowerCase(),
    },
    disableManagedEntity: "Disable manage customer mode",
    noAdmins: "Please <strong>add at least one admin</strong><br/> before creating the new {{customerType}}",
    search: {
      button: "Search {{customerType}}",
      placeholder: "Find {{customerType}} by name",
    },
  },
  landing: {
    signIn: {
      button: "Sign in",
      instruction: "Por favor, <strong>inicie sesión</strong> antes de acceder a esta página",
    },
  },
}

const products = {
  cdm: {
    description: "El mapeador de unidades líder en el mundo para Office 365",
    title: common.cdm,
  },
  conductor: {
    description: "Nuestra plataforma de automatización sobrealimentada",
    title: common.conductor,
  },
  idx: {
    description: "Conectar y sincronizar identidades entre sistemas",
    title: common.idx,
  },
  liftnshift: {
    description: "Eliminar la tensión de las migraciones de Office 365",
    title: common.liftnshift,
  },
  sso: {
    description: "Nuestra plataforma de inicio de sesión simple y segura",
    title: common.sso,
  },
  surpass: {
    description: "Descripción",
    title: common.surpass,
  },
}

const sales = {
  addANewCustomer: "Añadir un nuevo cliente",
  addCustomer: "Añadir cliente",
  address: common.address,
  addressLine: common.addressLine,
  applyResellerTax: "Aplicar impuesto de revendedor",
  business: "Negocio",
  cancel: common.cancel,
  city: "Ciudad",
  company_name: common.companyName,
  companyName_placeholder: "eg. Netflix LTD",
  companyRegistrationNumber: "Número de registro de la empresa",
  country: common.country,
  county: "Provincia",
  credit: "Nota de crédito",
  customer: "Cliente",
  customer_other: "Clientes",
  customerType: {
    all: "Todos los clientes",
    consumer: "Consumidor",
    consumer_other: "Consumidores",
    customer: common.customer,
    customer_other: common.customer_other,
    distributor: "Distribuidor",
    distributor_other: "Distribuidores",
    partner: "Socio",
    partner_other: "Socios",
    root: "Raíz",
    root_other: "Raíces",
  },
  customerTypeLabel: "Tipo de cliente",
  default_product_category: "Categoría de producto predeterminada",
  default_product_country_of_origin: "País de origen del producto predeterminado",
  deliveryAddress: "Dirección de entrega",
  deliveryDate: "Fecha de entrega",
  disableManagedEntity: "Deshabilitar el modo Administrar cliente",
  editCustomer: "Editar cliente",
  email: common.email,
  general: "General",
  individual: "Individual",
  invoice: "Factura",
  mobile: "Móvil",
  mobileNumber: "Número de teléfono móvil",
  name: common.name,
  next: "siguiente",
  nie: "NIE",
  nifOrCif: "NIF/CIF",
  notes: "Notas",
  orderDate: "Fecha del pedido",
  orderNumber: "Número de pedido",
  orderStatus: "Estado del pedido",
  orderValue: "Valor del pedido",
  paidAmount: "Monto de pago",
  payRemainder: "Pay remainder",
  payment: "Pago",
  paymentMethod: "Método de pago",
  pendingOrders: "Órdenes pendientes",
  phone: "Teléfono",
  placeholder: {
    companyName: "Nombre de la empresa",
    country: "Seleccionar pais",
    name: common.placeholderNetflix,
  },
  postCode: "Código postal",
  price_includes_tax: "El precio incluye el impuesto sobre las ventas",
  printReceipt: "Imprimir recibo",
  quantity: "Cantidad",
  refundOrExchange: "Reembolso o cambio",
  region: "Región",
  remainingAmount: "Cantidad restante",
  sales: "Ventas",
  salesOrders: {
    addNewOrder: "Agregar nuevo pedido",
    salesOrdersTitle: "Pedidos de ventas",
  },
  search: {
    button: "Buscar {{customerType}}",
    placeholder: "Buscar {{customerType}} por nombre",
    searchEmailOrMobileNumber: "Buscar correo electrónico o número de teléfono móvil",
    searchExistingCustomer: "Buscar un cliente existente",
  },
  shipments: "Métodos de envío",
  state: "Estado",
  status: common.status_label,
  storeFront: "Tienda",
  tax_registration_number: "Número de registro fiscal",
  totalPaid: "Total Pagado",
  totalRemaining: "Total Restante",
  townOrCity: "Pueblo/Ciudad",
  vatRegistrationNumber: "Número de registro de IVA",
  writeAddress: "Escribir dirección",
  writeNotes: "Escribe notas",
  zipCode: "Código Postal",
}

const purchases = {
  addNewSupplier: "Añadir nuevo proveedor",
  company_name: common.companyName,
  default_product_category: "Categoría de producto predeterminada",
  pending_orders: "Órdenes pendientes",
  purchase: "Compra",
  purchase_order: "Orden de compra",
  supplier: "Proveedor",
  supplier_credit: "crédito_proveedor",
  suppliers_other: "Proveedores",
}

const inventory = {
  addNewCategory: "Agregar nueva categoria",
  category_type: "Tipo de categoría",
  description: common.description,
  description_placeholder: "Escribir descripción",
  inventory: "Inventario",
  main_category: "Categoría principal",
  name: common.name,
  product: {
    add: common.addItem,
    addMore: "Añadir más",
    addNewProduct: `Agregar nuevo Producto`,
    addStoreFront: "Añadir una tienda",
    applyResellerTax: "Aplicar impuesto de revendedor",
    associatedProducts: {
      associateProducts: "Productos asociados",
      clickOnAssociatedProductToDelete: "Haga clic en el producto asociado que desea eliminar",
      crossLinkProducts: "Productos de enlace cruzado",
      deleted: "Eliminado",
      editAssociatedProducts: "Editar productos asociados",
      editCrossLinkedProducts: "Editar productos con enlaces cruzados",
      searchProductOrVariant: "Buscar un producto o variante de producto",
      searchProductToAssociate: "Buscar o escanear un producto para asociarlo",
      searchProductToCrossLink: "Buscar o escanear un producto para cruzarlo",
    },
    attributes: "Atributos",
    availableStockPerStoreFront: "Stock disponible por escaparate",
    back: common.back,
    carryingCost: "Costo de transporte",
    category: "Categoría",
    confirm: "Confirmar",
    countryOfOrigin: "País de origen",
    delete: common.delete,
    description: common.description,
    differentPricePerProductVariant: "Precio diferente por variante de producto",
    edit: common.editItem,
    editProduct: "Editar producto {{name}}",
    editVariant: "Editar variante",
    enterBarcodeManually: "Ingrese el código de barras manualmente",
    enterManually: "Entrar manualmente",
    hsCode: "Código SA",
    image: "Image",
    initialCostOfGoods: "Costo inicial de los bienes",
    initialStock: "Stock inicial",
    initialStockPerVariant: "Stock inicial por variante",
    inventory: "Inventario",
    inventoryType: "Tipo de inventario",
    makeDefault: common.makeDefault,
    makeItFavorite: "Hazlo favorito",
    name: common.name,
    newVariants: "Nueva variante",
    next: "siguiente",
    notes: "Notas",
    placeholder: {
      common: "Marcador de posición",
      customHSCode: "Código HS personalizado",
      name: common.placeholderNetflix,
      select: common.select,
      writeDescription: "Escribir una descripcion",
      writeShortName: "Escribir una descripcion",
    },
    price: "Precio",
    product: "Producto",
    product_low: "producto",
    product_other: "Productos",
    productCategory: "Categoría de producto",
    productImages: "Imágenes de productos",
    productInfo: "Información del producto",
    productName: "Nombre del producto",
    productVariantImages: "Nombre del producto",
    receiptDisplayName: "Nombre para mostrar del recibo",
    resellerTaxCategory: "Categoría de recargo de equivalencia",
    retailPricePerStoreFront: "Precio de venta al público por frente de tienda",
    reviewProductVariants: "Revisar variantes de productos",
    reviewVariant: "Revisar la Variante",
    reviewVariant_other: "Revisar las Variantes",
    rrp: "PVP",
    rrpIncludesTax: "El PVP incluye impuestos",
    salesTaxCategory: "Categoría de impuesto sobre las ventas",
    saveNnext: "Guardar y seguir",
    scanBarcode: "Escanear código de barras",
    select: common.select,
    selectStore: "Seleccionar tienda",
    shortName: "Nombre corto",
    sku: "SKU",
    status: common.status_label,
    statusActive: "Activo",
    statusInactive: "Inactivo",
    stockLevel: "Nivel de inventario",
    stockPerStoreFront: "Stock por frente de tienda",
    storeFront: common.storeFront,
    supplier: "Proveedor",
    supplierBarcode: "Código de barras del proveedor",
    supplierSKU: "SKU del proveedor",
    tags: "Etiquetas",
    taxInfo: "Información fiscal",
    totalProductVariants: "Total de variantes de productos",
    totalStock: "Total de existencias",
    unitCostPerVariant: "Costo unitario por variante",
    unitOfMeasure: "Unidad de medida",
    unitRRPPerVariant: "PVP unitario por variante",
    variant: "Variante",
    variantNumberOfAllVariants: "Variante {{variantNumber}} de {{allVariants}}",
    variantOption: "Opción variante",
    variantOption_other: "Opciones de variantes",
    variants: "Variantes",
    variantsAndStoreFronts: "Variantes y tiendas",
    variantsNumber: "{{count}} variantes",
    writeDownTheVariant: "Anota la variante",
  },
  product_category: {
    no_of_subcategories: "Nº de subcategorías",
  },
  sub_category: "Subcategoría",
  tax_category: "Categoría de impuestos",
  unitOfMeasure: {
    addNewUnit: "Agregar nueva unidad",
    addNewUnitOfMeasure: "Agregar nueva unidad de medida",
    shortName: "Nombre corto",
    unitOfMeasure: "Unidad de medida",
    unitOfMeasures: "Unidad de medidas",
  },
  variant_option: {
    add_new_option: "Agregar nueva opción de variante",
    add_new_option_value: "Agregar nuevo valor de opción",
    add_new_value: "Agregar nuevo valor",
    edit_value: "Edita el valor {{name}}",
    image_or_photo: "Valor de imagen/foto",
    no_of_option_values: "Número de valores de opción",
    option_colour: "Color de opción",
    option_image: "Imagen de opción",
    option_name: "Nombre de la opción",
    option_value: "valor de la opción",
    option_values: "Valores de opciones variantes",
    placeholder: {
      option_name: "por ejemplo, género, gusto",
      option_values: "Escribe un valor y presiona enter",
    },
    value: "Valor",
    value_option: "Opción de valor",
    variant_option: "Opción variante",
    variant_option_other: common.variant_option_other,
    variantValue: "Valor de variante",
  },
}

export const placeholder = {
  email: "nombre@dominio.com",
  name: "Nombre",
}

const validation: { [key in ValidationErrors]: string } = {
  email: "Correo electrónico",
  length: "Por favor ingrese {{min}} – {{max}} caracteres",
  letters: "Por favor ingrese solo letras",
  lettersNnumbers: "Por favor ingrese solo letras y números",
  lettersNnumbersNspecialCharacters: "Utilice solo letras, números y caracteres especiales",
  lettersNnumnersNdash: "Utilice solo letras, números y guiones",
  lettersNnumnersNdot: "Utilice solo letras, números y puntos",
  maxLength: "Por favor ingrese {{length}} caracteres o menos",
  minLength: "Por favor ingrese {{length}} caracteres o mas",
  numbers: "Utilice números",
  phone: "Por favor ingrese un número valido",
  required: "Por favor ingrese un {{field, lowercase}}",
  select: "Seleccione un {{field, lowercase}}",
}

const error = {
  auth: {
    heading: "Hold up!",
    message: "Por favor inicie sesión e intente de nuevo.",
  },
  generic: {
    heading: "¡Oopsiedoodles!",
    message: "Por favor, no llores. ¡Presiona F5 y volvamos a intentarlo!",
  },
  pageNotFound: {
    heading: "Las rosas son rojas, los círculos son redondos",
    link: "¡Llévame a casa!",
    message: "El código de barras escaneado no se puede encontrar en el sistema",
  },
  unauthorised: {
    heading: "Sorry, you don't have access to this page.",
    message: "Please contact your account manager.",
  },
}

const statusIcon: { [key in Status]: string } = {
  Accepted: "Aceptado",
  Error: "Error",
  Pending: "Pendiente",
  Rejected: "Rechazado",
}

const statuses: { [key in Statuses]: string } = {
  Active: "Activo",
  Inactive: "Inactivo",
}

const salesOrderStatus: { [key in SalesOrderStatus]: string } = {
  Canceled: "Cancelada",
  Paid: "Pagada",
  Pending: "Pendiente",
}

const roles: { [key in AdminRoles]: string } = {
  CDMAdministrator: "Administrador de CDM",
  CustomerAdministrator: "Administrador de clientes",
  DistributorAdministrator: "Administrador de distribuidores",
  Finance: "Finanzas",
  Helpdesk: "Mesa de ayuda",
  IACAdministrator: "Administrador de la nube de IAM",
  IDxAdministrator: "Administrador IDx",
  Legal: "Legal",
  MigrationAdministrator: "Administrador de migración",
  PartnerAdministrator: "Administrador de socios",
  PartnerManager: "Administrador de socios",
  TenancyAdministrator: "Administrador de arrendamiento",
}

const region = {
  eu: "UE",
  uk: "REINO UNIDO",
  us: "EEUU",
}

export const esEs = {
  translation: {
    ...common,
    ...components,
    ...features,
    ...inventory,
    ...inShop,
    ...purchases,
    ...sales,
    ...settings,
    error,
    placeholder,
    products,
    region,
    roles,
    salesOrderStatus,
    scannedBarcode,
    statusIcon,
    statuses,
    validation,
  },
}
